.cookie-notice-container{
	background-color: $purple;

	#cn-accept-cookie{
		background-image: none;
		background-color: white !important;
		color: $purple;
		border-radius: 0px;

		&:hover{
			background-color: rgb(235,235,235) !important;
		}
	}
}

.cc-color-override--595229065 .cc-btn:hover, .cc-color-override--595229065 .cc-btn:focus {

    color: #000 !important;
    background-color: $grey !important; 

}