@import url('https://fonts.googleapis.com/css?family=Archivo|Montserrat:400,600&display=swap');

body{
    font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	line-height: 32px;
}

ul{
	line-height: 36px;
}

b, strong{
	font-weight: 600;
	letter-spacing: 1px;
	color: $blue;
}

a{
	color: $blue;
	text-decoration: none;
}

a.btn{
	display: inline-block;
	background-color: $purple;
	color: white;
	padding: 6px 19px;
	border-radius: 0;
	font-size: 24px;
	font-weight: lighter;
	margin-top: 10px;

	&:hover{
		background-color: $purple-light;
		color: white !important;
	}

	&:after{
		display: none !important;
	}
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Archivo', sans-serif;
    color: $blue;
}

h1{
	font-size: 76px;
	line-height: 88px;
	margin-bottom: 25px;

	@include media-breakpoint-down(sm) {
		font-size: 42px;
		line-height: 54px;
		margin-bottom: 15px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 36px;
		line-height: 48px;
	}
}

h2{
	font-size: 48px;
	line-height: 60px;

	@include media-breakpoint-down(sm) {
		font-size: 34px;
		line-height: 46px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 30px;
		line-height: 42px;
	}
}

h3{
	font-size: 28px;
	line-height: 40px;

	@include media-breakpoint-down(sm) {
		font-size: 24px;
		line-height: 36px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 20px;
		line-height: 32px;
	}
}