.punten{
	background-color: $grey;
	padding-top: 35px;
	padding-bottom: 35px;
	position: relative;

	.punt-wrap{
		padding-top: 25px;
		padding-bottom: 25px;
		z-index: 10;

		.nummer-wrap{
			display: block;

			.nummer{
				background-color: $purple;
				color: white;
				height: 80px;
				width: 80px;
				border-radius: 60px;
				position: relative;
				margin: 0 auto;
				margin-bottom: 15px;

				span{
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					left: 50%;
					font-size: 48px;
				}
			}
		}

		.omschrijving{
			text-align: center;
		}
	}

	.pattern{
		position: absolute;
		top: 35px;
		right: 35px;
		pointer-events: none;
		z-index: 1;

		@media screen and (max-width: 2000px) {
			height: 350px;
		}

		@media screen and (max-width: 1400px) {
			height: 250px;
		}

		@include media-breakpoint-down(sm) {
			height: 350px;
			margin-top: 50px;
		}

		@include media-breakpoint-down(xs) {
			display: none;
		}
	}
}

.doe-mee-kaart{
	padding: 0;


	//Tooltip
	.mapsvg-tooltip{
		background-color: $purple !important;
		padding: 15px 35px 5px 10px !important;
		border-radius: 0 !important;
		border: none !important;
		margin-left: 20px;

		h4{
			font-size: 36px;
			line-height: 28px;
			color: white;
			margin-bottom: 0px !important;
		}

		i{
			font-size: 16px;
			color: white;
			display: block;
		}

		.nummers{
			margin-top: 5px;
			.nummer-wrap{
				display: inline-block;
				margin-right: 5px;

				.nummer{
					background-color: white;
					color: $purple;
					height: 35px;
					width: 35px;
					border-radius: 60px;
					position: relative;
					margin: 0 auto;

					span{
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
						left: 50%;
						font-size: 24px;
					}
				}

				&:last-of-type{
					margin-right: 0;
				}
			}
		}

		.meer-info{
			color: $blue;
			font-size: 16px;
		}

	}

	//Detail View
	.mapsvg-details-container{
		width: 100%;
		padding: 10vh 20vw;
		background-color: transparent;

		.mapsvg-controller-view{
			background-color: $purple;
			color: white;

			.mapsvg-popover-close{
				color: white;
				margin-top: 20px;
				margin-right: 20px;
			}

			h2{
				color: white;
				display: inline-block;
				border-bottom: 3px solid white;
				padding-bottom: 5px;
				margin-bottom: 35px;
			}

			.nummers{
				margin-top: 5px;
				display: inline-block;
				vertical-align: top;
				margin-left: 20px;

				.nummer-wrap{
					display: inline-block;
					margin-right: 5px;

					.nummer{
						background-color: white;
						color: $purple;
						height: 20px;
						width: 20px;
						border-radius: 60px;
						position: relative;
						margin: 0 auto;

						span{
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							left: 50%;
							font-size: 12px;
						}
					}

					&:last-of-type{
						margin-right: 0;
					}
				}
			}
		}

		.sdg-icon{
			position: absolute;
			height: 250px;
			right: 35px;
			top: 50%;
			transform: translateY(-50%);
			display: none;
		}
	}
}