.intro{
	position: relative;
	padding: 75px 0;
	overflow: hidden;

	.intro-content{
		z-index: 20;

		p{
			max-width: 80%;

			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
		}
	}

	.sdg-icon{
		position: absolute;
		top: -40px;
		left: 27%;
		z-index: 1;
		pointer-events: none;
		height: 115%;

		@include media-breakpoint-down(md) {
			left: -12%;
			opacity: 0.5;
		}

		@include media-breakpoint-down(xs) {
			height: 100%;
			left: -25%;
			top: -20px;
		}
	}

}

.news{
	background: $grey;
	padding: 75px 0;
	z-index: 20;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 50px 0;
	}

	h2{
		margin-bottom: 35px;

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-bottom: 25px;
		}
	}

	.slider{
		position: relative;
		z-index: 10;

		.arrow{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@include media-breakpoint-down(md) {
				position: static;
				width: 49.5%;
				display: inline-block;
				margin-top: 50px;
			}

			@include media-breakpoint-down(sm) {
				position: static;
				width: 49%;
				display: inline-block;
				margin-top: 50px;
			}

			span{
				position: relative;

				img{
					height: 75px;
					cursor: pointer;

					@media screen and (max-width: 1400px) {
						height: 45px;
					}

					@include media-breakpoint-down(xs) {
						height: 35px;
					}

				}

				.arrow-light{
					opacity: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-48%);
					left: 0;
					transition: all ease-in 0.1s;

					&:hover{
						opacity: 1;
					}
				}
			}
		}

		.arrow-prev{
			left: -8%;

			@media screen and (max-width: 1400px) {
				left: -4%;
			}

			@include media-breakpoint-down(md) {
				padding-right: 25px;
				text-align: right;
			}
		}

		.arrow-next{
			right: -8%;

			@media screen and (max-width: 1400px) {
				right: -4%;
			}

			@include media-breakpoint-down(md) {
				padding-left: 25px;
				text-align: left;
			}

		}

		.slider-wrap{

			.slick-slide{
			
				&:focus{
					outline: none;
				}

				&::-moz-focus-inner {
			  		border: 0;
				}

				.news-preview{
					height: 100%;
					background-color: white;
					position: relative;
					padding-bottom: 50px;
					z-index: 10;

					.image-wrapper{
						padding: 0 !important;
						width: 100%;
						height: 200px;
						overflow: hidden;

						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: 100% 0%;
						}
					}

					.text-wrap{
						padding: 35px;

						.title-link{
							text-decoration: none;

							h3{
								transition: all ease-in 0.1s;
								&:hover{
									color: $purple !important;
								}
							}
						}

						p{
							font-size: 16px;
							line-height: 28px;
						}
					}

					.read-more{
						color: $purple;
						text-decoration: none;
						position: absolute;
						bottom: 25px;
						left: 35px;

						&:after{
							content: '';
							display: block;
							width: 0;
							height: 3px;
							background: $purple;
							transition: all ease-in 0.1s;
						}

						&:hover{
							&:after{
								width: 100%;
							}
						}
					}

				}
			}

		}
	}

	.pattern{
		position: absolute;
		top: 35px;
		right: 35px;
		pointer-events: none;
		z-index: 1;

		@media screen and (max-width: 2000px) {
			height: 350px;
		}

		@media screen and (max-width: 1400px) {
			height: 250px;
		}

		@include media-breakpoint-down(sm) {
			height: 350px;
			margin-top: 50px;
		}

		@include media-breakpoint-down(xs) {
			display: none;
		}
	}

}

.homepage-content-wrap{
	overflow-x: hidden;

	.doe-mee-home{
		padding: 75px 0;
		position: relative;
		z-index: 20;
		margin-bottom: 100px;

		@include media-breakpoint-down(md) {
			margin-bottom: 0px;
		}

		.text-wrap{
			position: relative;
			z-index: 10;

		}

		.kaart{
			position: absolute;
			z-index: 1;
			right: 42%;
			pointer-events: none;

			@media screen and (max-width: 1400px) {
				height: 350px;
				right: 32%;
			}

			@include media-breakpoint-down(md) {
				height: 300px;
				right: -12%;
				top: 50%;
			}

			@include media-breakpoint-down(sm) {
				height: 200px;
				right: -12%;
				top: 55%;
			}
		}
	}


	.aanbevelingen-home{
		padding: 75px 0;
		position: relative;
		z-index: 20;

		.text-wrap{
			position: relative;
			z-index: 10;
		}

		.afbeelding{
			text-align: center;

			@include media-breakpoint-down(md) {
				padding: 50px;
				padding-bottom: 0;
			}

			img{
				max-height: 500px;
				max-width: 100%;

				@include media-breakpoint-down(md) {
					max-width: 100%;
					max-height: auto !important;
				}
			}
		}

	}

}