.py-3{
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

.subpage-content, .intro{
	padding: 75px 0;

	b, strong{
		color: $purple;
	}

	a{
		text-decoration: none;
		display: inline-block;

		b, strong{
			color: $blue !important;
			transition: all ease-in 0.1s;

			&:hover{
				color: $purple !important;
			}
		}

		&:after{
			content: '';
			display: block;
			width: 0;
			height: 3px;
			background: $purple;
			transition: all ease-in 0.1s;
		}

		&:hover{
			color: $purple;

			&:after{
				width: 100%;
			}

		}
	}

	.datum{
		span{
			color: $blue;
		}
	}
}


.contact-wrap{
	background: $grey;
	margin-bottom: 70px;

	.wpcf7{
		label{
			span{
				input{
					border: none;
					border-bottom: 3px solid $blue;
					background-color: transparent;
					padding: 7px 20px;
					color: black;
					margin-bottom: 15px;

					@include media-breakpoint-down(md) {
						width: 100%;
					}

					&::placeholder{
						color: black;
						opacity: 0.75;
					}
				}

				.wpcf7-not-valid-tip{
					padding-left: 20px;
					color: $blue;
					font-style: italic;
					font-size: 16px;
				}

				textarea{
					margin-top: 15px;
					border: none;
					background-color: white;
					color: $blue;
					padding: 12px 20px;

					&::placeholder{
						color: black;
						opacity: 0.75;
					}

					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
			}
		}

		.wpcf7-submit{
			margin-top: 10px;
			background-color: white;
			color: $blue;
			border: none;
			padding: 10px 17px;
			transition: all ease-in 0.1s;

			&:hover{
				background-color: $blue;
				color: white;

			}
		}

		.wpcf7-response-output{
			margin: 0 !important;
			margin-top: 35px !important;
			background-color: white !important;
			color: $blue !important;
			font-size: 16px;
			font-style: italic;
			border: none;
		}

	}
}

.sdgs-lokaal-wrap{

	.container{
		position: relative;

		.article-body{
			p{
				@include media-breakpoint-down(md) {
					margin-bottom: 35px;
				}
			}
		}

		.brochure-download{

			@include media-breakpoint-down(md) {
				margin-bottom: 35px;
			}

			&:after{
				display: none !important;
			}

			img{
				width: 100%;
			}
		}

		.sdg-icon{
			position: absolute;
			top: 50px;
			right: 25px;
			height: 400px;
			pointer-events: none;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

	}

}