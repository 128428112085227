//Algemeen

.aanbevelingen-smartphone{
	display: none;
}

@media only screen and (max-width: 992px){

	.aanbevelingen-desktop{
		display: none;
	}

	.aanbeveling-column.aanbevelingen-smartphone{
		display: block;

		.aanbeveling{
			.nummer-wrap{
				display: block;
				width: 100%;
				margin-bottom: 15px;

				.nummer{
					margin: 0 auto;
				}
			}

			.titel{
				display: block;
				width: 100%;

				h2{
					text-align: center;
				}
			}

			.content{
				text-align: center;
			}
		}
	}

}

.aanbeveling-column{
	padding: 0;

	.aanbeveling{
		padding: 100px;

		@media screen and (max-width: 1400px) {
			padding: 50px;
		}

		@include media-breakpoint-down(md) {
			padding: 35px;
		}

		@include media-breakpoint-down(xs) {
			padding: 35px 15px;
		}

		.titel{
			display: inline-block;
			width: 91%;

			@media screen and (max-width: 1400px) {
				width: 88%;
			}

			h2{
				margin-bottom: 35px;

				@include media-breakpoint-down(md) {
					margin-bottom: 20px;
				}
			}
		}

		.nummer-wrap{
			display: inline-block;
			width: 8%;
			text-align: right;
			vertical-align: top;

			.nummer{
				height: 80px;
				width: 80px;
				border-radius: 60px;
				position: relative;

				@media screen and (max-width: 1400px) {
					height: 70px;
					width: 70px;
				}

				@include media-breakpoint-down(md) {
					height: 60px;
					width: 60px;
				}

				span{
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					left: 50%;
					font-size: 48px;

					@media screen and (max-width: 1400px) {
						font-size: 42px;
					}

					@include media-breakpoint-down(md) {
						font-size: 32px;
					}
				}
			}
		}

		.video{
			margin-top: 25px;
			text-align: center;

			iframe{
				max-width: 100%;
			}
		}

		.afbeelding{
			width: 100%;
			margin: 0 auto;
			text-align: center;
			img{
				max-width: 100%;
			}
		}
	}
}

//Stijlen

.aanbeveling-blauw{
	background-color: $blue;

	.titel{
		h2{
			color: white;
		}
	}

	.nummer-wrap{
		.nummer{
			background-color: white;
			color: $blue;
		}
	}

	strong, b, a{
		color: white !important;
	}
}

.aanbeveling-paars{
	background-color: $purple;

	.titel{
		h2{
			color: white;
		}
	}

	.nummer-wrap{
		.nummer{
			background-color: white;
			color: $purple;
		}
	}

	p{
		color: white !important;
	}

	strong, b, a{
		color: $blue !important;
	}
}

.aanbeveling-grijs{
	background-color: $grey;

	.titel{
		h2{
			color: $blue;
		}
	}

	.nummer-wrap{
		.nummer{
			background-color: $blue;
			color: white;
		}
	}

	strong, b, a{
		color: $blue !important;
	}

}

.aanbeveling-wit{
	background-color: white;

	.titel{
		h2{
			color: $purple;
		}
	}

	.nummer-wrap{
		.nummer{
			background-color: $purple;
			color: white;
		}
	}

	strong, b{
		color: $purple !important;
	}

	a{
		color: $blue;
	}
}