* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
    outline: none !important;
}

header{
	min-height: 300px;
	position: relative;
	z-index: 2;
	background-color: white;

	@include media-breakpoint-down(lg) {
		min-height: 250px;
	}

	@include media-breakpoint-down(md) {
		min-height: 225px;
		z-index: 40;
	}

	@include media-breakpoint-down(sm) {
		min-height: 200px;
	}

	.header-image{
		position: absolute;
		min-height: 100%;
		width: 100%;
		background-size: cover !important;
  		filter: grayscale(100%) !important;
  		opacity: 0.75;
		z-index: 3;
	}

	.overlay{
		position: absolute;
		background: $blue;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.6;
		z-index: 10;
	}

	#nav-main{
		margin-top: 25px;
		padding: 0 75px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: transparent !important;
		z-index: 15;

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			padding: 0;
		}

		.navbar-brand{
			font-size: 20px;
			font-weight: bold;
			color: white;
			text-transform: uppercase;
			position: relative;
			top: 25px;

			@include media-breakpoint-down(md) {
				left: 25px;
				top: 5px;
			}

			span{
				font-size: 10px;
				font-weight: normal;
				position: relative;
				left: 1px;
				bottom: -7px;

				img{
					width: 135px;
					margin-left: 5px;
					position: relative;
					top: -9px;
				}
			}
		}

		#menu-entries{
			display: block !important;
			padding-top: 20px;
			
			@include media-breakpoint-down(md) {
				background-color: $purple;
				padding: 25px;
				margin-top: 25px;
			}

			.nav{
				float: right;

				.nav-item{
					padding: 0 15px;

					@include media-breakpoint-down(lg) {
						padding: 0 10px;
					}

					@include media-breakpoint-down(md) {
						padding: 0;
					}

					.nav-link{
						color: white;
						font-weight: bold;
						letter-spacing: 0.5px;
						padding: 0;
						padding-bottom: 5px;
						font-size: 16px;

						&:after{
							content: '';
							display: block;
							width: 0;
							height: 3px;
							background: white;
							transition: all ease-in 0.1s;
						}

						&:hover{
							&:after{
								width: 100%;
							}
						}

						&:focus{
							&:after{
								width: 100%;
							}
						}

						@include media-breakpoint-down(lg) {
							font-size: 16px;
						}

						@include media-breakpoint-down(md) {
							font-size: 18px;
							display: inline-block;
							color: white;
							font-weight: 400;

							&:after{
								content: '';
								display: block;
								width: 0;
								height: 3px;
								background: white;
								transition: all ease-in 0.1s;
							}

							&:hover{
								&:after{
									width: 100%;
								}
							}

							&:focus{
								&:after{
									width: 100%;
								}
							}
						}

					}

					&:first-of-type{
						padding-left: 0;
					}

					&:last-of-type{
						padding-right: 0;
					}
				}

				.nav-item.current-menu-item{
					.nav-link{
						&:after{
							content: '';
							display: block;
							width: 100%;
							height: 3px;
							background: white;
						}
					}
				}

			}
		}

	}

}

.page-id-43 header{

	min-height: 300px;

	@include media-breakpoint-up(md) {
		min-height: 350px;
	}

	@include media-breakpoint-up(lg) {
		min-height: 400px;
	}

	@include media-breakpoint-up(xl) {
		min-height: 450px;
	}

}

.col{
	padding: 0;
}

.navbar-toggler{
	background-color: $purple;
	border-radius: 0;
	margin-right: 25px;

    .navbar-toggler-icon{
   		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}

/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	header #nav-main{
		#menu-entries{
			display: none !important;
		
			&.show{
				display: inherit !important;
			}
		}
	}
} 