.partners{
	background-color: $purple;
	padding: 35px 0;

	h3{
		text-align: center;
		color: white;
		font-size: 36px;
		line-height: 48px;
		margin-bottom: 35px;
	}

	.partner{
		a{
			display: block;
			padding: 0 50px;

			@include media-breakpoint-down(md) {
				padding: 0 25px;
			}

			@include media-breakpoint-down(sm) {
				padding: 0 150px;
				margin-bottom: 50px;
			}

			@include media-breakpoint-down(xs) {
				padding: 0 75px;
				margin-bottom: 50px;
			}

			img{
				width: 100%;
			}
		}
	}


}

footer{

	padding: 35px 0;

	.widget{
		h3{
			font-size: 36px;
			line-height: 48px;
		}

		p{
			line-height: 36px;
		}

		ul{
			padding: 0;

			li{
				list-style-type: none;

				a{
					display: inline-block;
					color: black;
					transition: all ease-in 0.1s;
					text-decoration: none !important;

					&:after{
						content: '';
						display: block;
						width: 0;
						height: 3px;
						background: $purple;
						transition: all ease-in 0.1s;
					}

					&:hover{
						color: $purple;

						&:after{
							width: 100%;
						}

					}
				}
			}
		}

	}

	.disclaimer{
		padding-top: 56px;

		@include media-breakpoint-down(md) {
			padding-top: 15px;
		}
	}
	
}




.copyright{
	padding: 15px 0;
	text-align: center;
	background-color: $grey;

	a{
		text-decoration: none;
		transition: all ease-in 0.1s;
		display: inline-block;

		&:after{
			content: '';
			display: block;
			width: 0;
			height: 3px;
			background: $purple;
			transition: all ease-in 0.1s;
		}

		&:hover{
			color: $purple;

			&:after{
				width: 100%;
			}

		}
	}
}